@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "CreditCard";
  src: local("CreditCard"),
   url("./fonts/CREDC___.ttf") format("truetype");
  font-weight: bold;
 }
 @font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"),
   url("./fonts/SpaceGrotesk-VariableFont_wght.ttf") format("truetype");
  /* font-weight: bold; */
 }
